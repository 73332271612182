<div class="modal-header">
    <h5 class="modal-title fw-bold">Account Codes</h5>
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button"></button>
</div>

<div class="modal-body p-0">
    <div id="stepper3" class="bs-stepper vertical linear">
        <div class="bs-stepper-header" role="tablist">
            <div class="step" data-target="#addAccounts" [class.active]="activeTab === 'addAccounts'">
                <div class="step-trigger" role="tab" id="stepper3trigger1" aria-controls="addAccounts" (click)="setTab('addAccounts')">
                    <div class="bs-stepper-circle"><i class="fa-solid fa-tags fs-4"></i></div>
                    <div class="">
                        <h5 class="mb-0 steper-title">Add Accounts to Xero</h5>
                        <p class="mb-0 steper-sub-title text-wrap">Add the following accounts to your Xero Chart of Accounts.</p>
                    </div>
                </div>
            </div>

            <div class="step" data-target="#mapAccounts" [class.active]="activeTab === 'mapAccounts'">
                <div class="step-trigger" role="tab" id="stepper3trigger2" aria-controls="mapAccounts" (click)="setTab('mapAccounts')">
                    <div class="bs-stepper-circle"><i class="fa-solid fa-circle-nodes fs-4"></i></div>
                    <div class="">
                        <h5 class="mb-0 steper-title">Setup Accounts Mappings</h5>
                        <p class="mb-0 steper-sub-title text-wrap">Choose which accounts are mapped to your Plus categories.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="bs-stepper-content w-100">
            <div id="addAccounts" class="bs-stepper-pane p-3 content fade dstepper-block" [class.active]="activeTab === 'addAccounts'">
                <div class="row g-3">
                    <div class="col-12">
                        <div class="list-group list-group-flush">
                            <a class="list-group-item list-group-item-action w-100 d-flex flex-fill justify-content-between align-items-center">
                                <div>
                                    <p class="m-0"><b>Account Type:</b> Revenue <span class="badge bg-warning">Missing</span></p>
                                    <p class="m-0"><b>Code:</b> VP-1</p>
                                    <p class="m-0"><b>Name:</b> Vehicle Purchase</p>
                                    <p class="m-0"><b>Description:</b> Vehicle sales in MTA Plus.</p>
                                </div>

                                <div>
                                    <button class="btn btn-primary btn-sm">Add to Xero</button>
                                </div>
                            </a>

                            <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div>
                                    <p class="m-0"><b>Account Type:</b> Revenue <span class="badge bg-warning">Missing</span></p>
                                    <p class="m-0"><b>Code:</b> VP-1</p>
                                    <p class="m-0"><b>Name:</b> Vehicle Purchase</p>
                                    <p class="m-0"><b>Description:</b> Vehicle sales in MTA Plus.</p>
                                </div>

                                <div>
                                    <button class="btn btn-primary btn-sm">Add to Xero</button>
                                </div>
                            </a>

                            <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div>
                                    <p class="m-0"><b>Account Type:</b> Revenue <span class="badge bg-warning">Missing</span></p>
                                    <p class="m-0"><b>Code:</b> VP-1</p>
                                    <p class="m-0"><b>Name:</b> Vehicle Purchase</p>
                                    <p class="m-0"><b>Description:</b> Vehicle sales in MTA Plus.</p>
                                </div>

                                <div>
                                    <button class="btn btn-primary btn-sm">Add to Xero</button>
                                </div>
                            </a>

                            <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div>
                                    <p class="m-0"><b>Account Type:</b> Revenue <span class="badge bg-warning">Missing</span></p>
                                    <p class="m-0"><b>Code:</b> VP-1</p>
                                    <p class="m-0"><b>Name:</b> Vehicle Purchase</p>
                                    <p class="m-0"><b>Description:</b> Vehicle sales in MTA Plus.</p>
                                </div>

                                <div>
                                    <button class="btn btn-primary btn-sm">Add to Xero</button>
                                </div>
                            </a>

                            <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div>
                                    <p class="m-0"><b>Account Type:</b> Revenue <span class="badge bg-warning">Missing</span></p>
                                    <p class="m-0"><b>Code:</b> VP-1</p>
                                    <p class="m-0"><b>Name:</b> Vehicle Purchase</p>
                                    <p class="m-0"><b>Description:</b> Vehicle sales in MTA Plus.</p>
                                </div>

                                <div>
                                    <button class="btn btn-primary btn-sm">Add to Xero</button>
                                </div>
                            </a>

                            <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div>
                                    <p class="m-0"><b>Account Type:</b> Revenue <span class="badge bg-warning">Missing</span></p>
                                    <p class="m-0"><b>Code:</b> VP-1</p>
                                    <p class="m-0"><b>Name:</b> Vehicle Purchase</p>
                                    <p class="m-0"><b>Description:</b> Vehicle sales in MTA Plus.</p>
                                </div>

                                <div>
                                    <button class="btn btn-primary btn-sm">Add to Xero</button>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="mapAccounts" class="bs-stepper-pane p-3 content fade dstepper-block" [class.active]="activeTab === 'mapAccounts'">
                <div class="row pt-3 g-3">
                    <div class="col-12 col-md-6">
                        <div class="mb-3">
                            <div class="form-label text-center">Category</div>
                            <input class="form-control" type="text" value="Vehicle Purchases" readonly disabled />
                        </div>

                        <div class="mb-3">
                            <input class="form-control" type="text" value="Warranty Purchases" readonly disabled />
                        </div>

                        <div class="mb-3">
                            <input class="form-control" type="text" value="Finance Purchases" readonly disabled />
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="mb-3">
                            <div class="form-label text-center">Account</div>
                            <ng-select class="form-control" placeholder="VP-100"></ng-select>
                        </div>

                        <div class="mb-3">
                            <ng-select class="form-control" placeholder="WP-100"></ng-select>
                        </div>

                        <div class="mb-3">
                            <ng-select class="form-control" placeholder="FP-100"></ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer justify-content-between">
    <!--<button (click)="activeModal.dismiss(null)" class="btn btn-link">close</button>-->
    <div>
        @if (activeTab !== 'addAccounts') {
            <button class="btn btn-outline-primary" (click)="previousTab()"><i class="fas fa-arrow-left"></i> Previous</button>
        }
    </div>

    <div>
        @if (activeTab === 'mapAccounts') {
            <button class="btn btn-primary" type="button">Finish</button>
        } @else {
             <button class="btn btn-primary me-1"><i class="fa-solid fa-plus"></i> Add All Accounts</button>
            <button class="btn btn-secondary me-1"><i class="fa-solid fa-rotate"></i> Refresh Accounts</button>
            <button class="btn btn-primary" (click)="nextTab()">Next <i class="fa-solid fa-arrow-right"></i></button>
        }
    </div>
</div>
