import {Component}      from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-accounts-connection-modal',
    templateUrl: './account-code-modal.component.html',
    styleUrl: './account-code-modal.component.scss'
})
export class AccountCodeModalComponent
{
    public activeTab: string = 'addAccounts';
    public tabs: string[] = ['addAccounts', 'mapAccounts'];

    constructor(
        public activeModal: NgbActiveModal,
    )
    {
    }

    public setTab(tab: string): void
    {
        // if (this.canChangeToTab(tab)) {
        this.activeTab = tab;
        // }
    }

    public previousTab(): void
    {
        const currentIndex: number = this.tabs.indexOf(this.activeTab);

        if (currentIndex > 0) {
            const previousTab = this.tabs[currentIndex - 1];
            this.setTab(previousTab);
        }
    }

    public nextTab(): void
    {
        const currentIndex: number = this.tabs.indexOf(this.activeTab);

        if (currentIndex < this.tabs.length - 1) {
            const nextTab = this.tabs[currentIndex + 1];
            this.setTab(nextTab);
        }
    }
}
