import {DEFAULT_CURRENCY_CODE, Injectable, LOCALE_ID, NgModule}                                 from '@angular/core';
import {BrowserModule}                                                                          from '@angular/platform-browser';
import {AppRoutingModule}                                                                       from './app-routing.module';
import {AppComponent}                                                                           from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch, withInterceptors}    from "@angular/common/http";
import {NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastsContainerComponent}                                                               from './shared/components/toasts-container/toasts-container.component';
import {LoginComponent}                                                                         from "./core/pages/login/login.component";
import {ResetPasswordComponent}                                                                 from "./core/pages/reset-password/reset-password.component";
import {ForgotPasswordComponent}                                                                from "./core/pages/forgot-password/forgot-password.component";
import {ConfirmEmailComponent}                                                                  from "./core/pages/confirm-email/confirm-email.component";
import {FormsModule, ReactiveFormsModule}                                                       from "@angular/forms";
import {TokenInterceptor}                                                                       from "./core/interceptors/token.interceptor";
import {JWT_OPTIONS, JwtHelperService}                                                          from "@auth0/angular-jwt";
import {NgxSpinnerModule}                                                                       from "ngx-spinner";
import {BrowserAnimationsModule}                                                                from "@angular/platform-browser/animations";
import {AdminModule}                                                                            from "./features/admin.module";
import {VerifyEmailComponent}                                                                   from "./core/pages/verify-email/verify-email.component";
import {provideHttpCache, withHttpCacheInterceptor}                                             from "@ngneat/cashew";
import {NgxExtendedPdfViewerModule}                                                             from 'ngx-extended-pdf-viewer';
import {DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule}                               from "ngx-dropzone-wrapper";
import {NgSelectModule}                                                                         from "@ng-select/ng-select";
import {RegisterComponent}                                                                      from './core/pages/register/register.component';
import {FullCalendarModule}                                                                     from "@fullcalendar/angular";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter
{
    public readonly DELIMITER: '/' = '/';

    public parse(value: string): NgbDateStruct | null
    {
        if (value) {
            const date: string[] = value.split(this.DELIMITER);

            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10),
            };
        }

        return null;
    }

    public format(date: NgbDateStruct | null): string
    {
        return date
            ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
            : '';
    }
}

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    url: 'https://httpbin.org/post',
    maxFilesize: 50,
    acceptedFiles: 'image/*'
};

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        ConfirmEmailComponent,
        VerifyEmailComponent,
        RegisterComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FullCalendarModule,
        NgxSpinnerModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        ToastsContainerComponent,
        FormsModule,
        ReactiveFormsModule,
        AdminModule,
        NgxExtendedPdfViewerModule,
        DropzoneModule,
    ],
    exports: [
        NgSelectModule,
        NgxSpinnerModule,
    ],
    providers: [
        provideHttpClient(withFetch(), withInterceptors([withHttpCacheInterceptor()])),
        provideHttpCache(),
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: LOCALE_ID, useValue: 'en-GB'},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'GBP'},
        {provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG},
        {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
        JwtHelperService,
        {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    ],
    bootstrap: [AppComponent]
})
export class AppModule
{
}
