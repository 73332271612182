<div class="authentication-header">
    <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
</div>

<div class="login-panel d-flex align-items-center justify-content-center">
    <div class="card p-4 w-25">
        <div class="card-body pt-0">
            <div class="text-center">
                <img alt="" src="assets/images/MTALogoNoBackground-01.png" class="logo" />

                <div class="my-4">
                    <h5 class="card-title">Verify Email</h5>
                    <p class="card-text">We have sent you an email to verify your address, don't forget to check your junk folder.</p>
                </div>
            </div>

            <form #ngForm="ngForm" (ngSubmit)="onSubmit()" [formGroup]="verifyEmailForm" class="row g-3">
                <div class="mb-3">
                    <label class="form-label">Email</label>
                    <input class="form-control" formControlName="email" placeholder="example@user.com" type="email" />
                    <div *ngIf="verifyEmailForm.get('email')?.invalid && (verifyEmailForm.get('email')?.dirty || verifyEmailForm.get('email')?.touched)" class="invalid-feedback">
                        <ng-container *ngIf="verifyEmailForm.get('email')?.errors?.['required']">Please provide an email address.</ng-container>
                        <ng-container *ngIf="verifyEmailForm.get('email')?.errors?.['pattern']">Please provide a valid email address.</ng-container>
                    </div>
                </div>

                <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-primary" [disabled]="verifyEmailForm.disabled">Submit</button>
                </div>

                <div class="col-12 col-md-10">
                    <span *ngIf="timeRemaining$ | async" class="text-muted">Didn't receive your email? Request another in <br /> <span class="text-primary">{{ timeRemaining$ | async | date:'ss' }} seconds</span></span>
                </div>

                <div class="col-12 col-md-2 text-end">
                    <a routerLink="/login">Cancel</a>
                </div>
            </form>
        </div>
    </div>
</div>
