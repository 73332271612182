import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastService} from "../../../shared/services/toast.service";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss', '../../../../assets/scss/circles.scss']
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup = new FormGroup('');
    public submitted: boolean = false;
    public error: any;
    public revealPassword: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private authService: AuthService,
        private toastService: ToastService,
    ) {
    }

    public ngOnInit(): void {
        this.loginForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
            password: [null, Validators.required]
        });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (this.loginForm.invalid) {
            // TODO: Show invalid credentials error
            return;
        }

        this.loginForm.disable();

        this.authService.login({
            email: this.loginForm.get('email')!.value,
            password: this.loginForm.get('password')!.value,
            frontend_url: `${environment.localPath}/confirm-email`
        }).subscribe({
            next: () => {
                const redirectUrl: string = this.activatedRoute.snapshot.queryParamMap.get('redirectUrl') || '/app';

                this.toastService.success('Signed in successfully.');
                this.router.navigateByUrl(redirectUrl);
            },
            error: (response: HttpErrorResponse) => {
                this.loginForm.reset();
                this.loginForm.enable();

                this.error = response;

                this.toastService.error(response?.error?.message);
            }
        });
    }
}
