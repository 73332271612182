{
  "name": "mta-plus",
  "version": "0.9.31",
  "scripts": {
    "ng": "ng",
    "start": "ng serve -o",
    "build": "ng build",
    "build:test": "ng build --configuration=testing  --progress",
    "build:staging": "ng build --configuration=staging  --progress",
    "build:production": "ng build --configuration=production  --progress",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:mta-plus": "node dist/mta-plus/server/server.mjs",
    "lint": "eslint .",
    "lint:fix": "eslint . --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.2",
    "@angular/cdk": "^17.3.2",
    "@angular/common": "^17.3.2",
    "@angular/compiler": "^17.3.2",
    "@angular/core": "^17.3.2",
    "@angular/forms": "^17.3.2",
    "@angular/material": "^17.3.2",
    "@angular/platform-browser": "^17.3.2",
    "@angular/platform-browser-dynamic": "^17.3.2",
    "@angular/platform-server": "^17.3.2",
    "@angular/router": "^17.3.2",
    "@angular/ssr": "^17.3.2",
    "@auth0/angular-jwt": "^5.2.0",
    "@ckeditor/ckeditor5-angular": "^9.0.0",
    "@fullcalendar/angular": "^6.1.15",
    "@fullcalendar/bootstrap5": "^6.1.15",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/list": "^6.1.15",
    "@fullcalendar/multimonth": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.15",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ng-select/ng-select": "^12.0.7",
    "@ngneat/cashew": "^4.1.0",
    "@popperjs/core": "^2.11.8",
    "@sweetalert2/themes": "^5.0.16",
    "@types/html2canvas": "^1.0.0",
    "@types/ical": "^0.8.3",
    "@types/jest": "^29.5.12",
    "@types/jspdf": "^2.0.0",
    "@vortechron/query-builder-ts": "^1.2.0",
    "apexcharts": "^3.49.1",
    "bootstrap": "^5.3.3",
    "ckeditor5": "^43.2.0",
    "date-fns": "^3.6.0",
    "express": "4.21.2",
    "html2canvas": "^1.4.1",
    "ical.js": "^2.1.0",
    "js-query-builder": "^0.2.1",
    "jspdf": "^2.5.1",
    "jspdf-autotable": "^3.8.2",
    "moment": "^2.30.1",
    "ng-apexcharts": "1.10.0",
    "ngx-bootstrap": "^12.0.0",
    "ngx-clipboard": "^16.0.0",
    "ngx-dropzone-wrapper": "^16.0.0",
    "ngx-extended-pdf-viewer": "^19.2.0",
    "ngx-file-drop": "^16.0.0",
    "ngx-mask": "^17.0.4",
    "ngx-scrollbar": "^14.0.0-beta.0",
    "ngx-spinner": "^16.0.2",
    "rxjs": "~7.8.0",
    "slugify": "^1.6.6",
    "sweetalert2": "^11.10.4",
    "tslib": "^2.3.0",
    "uuid": "^11.0.3",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.2",
    "@angular-eslint/builder": "17.3.0",
    "@angular-eslint/eslint-plugin": "17.3.0",
    "@angular-eslint/eslint-plugin-template": "17.3.0",
    "@angular-eslint/schematics": "17.3.0",
    "@angular-eslint/template-parser": "17.3.0",
    "@angular/cli": "^17.3.2",
    "@angular/compiler-cli": "^17.3.2",
    "@angular/localize": "^17.3.2",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.19.10",
    "@typescript-eslint/eslint-plugin": "6.18.0",
    "@typescript-eslint/parser": "6.18.0",
    "eslint": "^8.56.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.2.2"
  },
  "optionalDependencies": {
    "@nx/nx-darwin-arm64": "18.0.4",
    "@nx/nx-darwin-x64": "18.0.4",
    "@nx/nx-linux-x64-gnu": "18.0.4",
    "@nx/nx-win32-x64-msvc": "18.0.4"
  }
}
