@if (authService.accountIntegration?.active) {
    <div>
        <div class="dash-wrapper">
            <div class="row row-cols-1 row-cols-md-2 row-cols-xl-5 row-cols-xxl-5">
                <div class="col border-end border-light-2">
                    <div class="card bg-transparent shadow-none mb-0">
                        <div class="card-body text-center">
                            <p class="mb-1 text-white">Vehicles Purchased</p>
                            <h3 class="mb-3 text-white">{{VehiclesInStock.length > 0 ? VehiclesInStock[VehiclesInStock.length - 1] : 0}}</h3>

                            <p class="font-13 text-white">
                                <span class="{{calculateAverage(VehiclesInStock).positive ? 'text-success' : 'text-danger'}}">
                                    <i class="lni {{calculateAverage(VehiclesInStock).positive ? 'lni-arrow-up' : 'lni-arrow-down'}}"></i>
                                    {{calculateAverage(VehiclesInStock).percentage}}%
                                </span>
                                vs last 7 days
                            </p>

                            <apx-chart #chartObj [chart]="vehiclePurchasedOptions.chart!"
                                [colors]="vehiclePurchasedOptions.colors!" [dataLabels]="vehiclePurchasedOptions.dataLabels!"
                                [markers]="vehiclePurchasedOptions.markers!"
                                [plotOptions]="vehiclePurchasedOptions.plotOptions!"
                                [series]="vehiclePurchasedOptions.series!" [stroke]="vehiclePurchasedOptions.stroke!"
                                [title]="vehiclePurchasedOptions.title!" [tooltip]="vehiclePurchasedOptions.tooltip!"
                                [xaxis]="vehiclePurchasedOptions.xaxis!" [yaxis]="vehiclePurchasedOptions.yaxis!"></apx-chart>
                        </div>
                    </div>
                </div>

                <div class="col border-end border-light-2">
                    <div class="card bg-transparent shadow-none mb-0">
                        <div class="card-body text-center">
                            <p class="mb-1 text-white">Deals In Progress</p>
                            <h3 class="mb-3 text-white">{{Enquiries.length > 0 ?
                                Enquiries[Enquiries.length - 1] : 0}}</h3>
                            <p class="font-13 text-white"><span
                                    class="{{calculateAverage(Enquiries).positive ? 'text-success' : 'text-danger'}}"><i
                                        class="lni {{calculateAverage(Enquiries).positive ? 'lni-arrow-up' : 'lni-arrow-down'}}"></i>{{calculateAverage(Enquiries).percentage}}%</span>
                                vs last 7 days</p>
                            <apx-chart #chartObj [chart]="enquiriesOptions.chart!" [colors]="enquiriesOptions.colors!"
                                [dataLabels]="enquiriesOptions.dataLabels!" [fill]="enquiriesOptions.fill!"
                                [grid]="enquiriesOptions.grid!" [markers]="enquiriesOptions.markers!"
                                [plotOptions]="enquiriesOptions.plotOptions!" [series]="enquiriesOptions.series!"
                                [stroke]="enquiriesOptions.stroke!" [title]="enquiriesOptions.title!"
                                [tooltip]="enquiriesOptions.tooltip!" [yaxis]="enquiriesOptions.yaxis!"></apx-chart>
                        </div>
                    </div>
                </div>

                <div class="col border-end border-light-2">
                    <div class="card bg-transparent shadow-none mb-0">
                        <div class="card-body text-center">
                            <p class="mb-1 text-white">Vehicles Sold</p>
                            <h3 class="mb-3 text-white">{{VehiclesSold.length > 0 ?
                                VehiclesSold[VehiclesSold.length - 1] : 0}}</h3>
                            <p class="font-13 text-white"><span
                                    class="{{calculateAverage(VehiclesSold).positive ? 'text-success' : 'text-danger'}}"><i
                                        class="lni {{calculateAverage(VehiclesSold).positive ? 'lni-arrow-up' : 'lni-arrow-down'}}"></i>
                                    {{calculateAverage(VehiclesSold).percentage}}%</span> vs last 7 days</p>
                            <apx-chart #chartObj [chart]="vehiclesSoldOptions.chart!" [colors]="vehiclesSoldOptions.colors!"
                                [dataLabels]="vehiclesSoldOptions.dataLabels!" [fill]="vehiclesSoldOptions.fill!"
                                [grid]="vehiclesSoldOptions.grid!" [markers]="vehiclesSoldOptions.markers!"
                                [plotOptions]="vehiclesSoldOptions.plotOptions!" [series]="vehiclesSoldOptions.series!"
                                [stroke]="vehiclesSoldOptions.stroke!" [title]="vehiclesSoldOptions.title!"
                                [tooltip]="vehiclesSoldOptions.tooltip!" [yaxis]="vehiclesSoldOptions.yaxis!"></apx-chart>
                        </div>
                    </div>
                </div>

                <div class="col border-end border-light-2">
                    <div class="card bg-transparent shadow-none mb-0">
                        <div class="card-body text-center">
                            <p class="mb-1 text-white">SOR Stock</p>
                            <h3 class="mb-3 text-white">{{SORStock.length > 0 ?
                                SORStock[SORStock.length - 1] : 0}}</h3>
                            <p class="font-13 text-white"><span
                                    class="{{calculateAverage(SORStock).positive ? 'text-success' : 'text-danger'}}"><i
                                        class="lni {{calculateAverage(SORStock).positive ? 'lni-arrow-up' : 'lni-arrow-down'}}"></i>
                                    {{calculateAverage(SORStock).percentage}}%</span> vs last 7 days</p>
                            <apx-chart #chartObj [chart]="sorStockOptions.chart!" [colors]="sorStockOptions.colors!"
                                [dataLabels]="sorStockOptions.dataLabels!" [fill]="sorStockOptions.fill!"
                                [grid]="sorStockOptions.grid!" [markers]="sorStockOptions.markers!"
                                [plotOptions]="sorStockOptions.plotOptions!" [series]="sorStockOptions.series!"
                                [stroke]="sorStockOptions.stroke!" [title]="sorStockOptions.title!"
                                [tooltip]="sorStockOptions.tooltip!" [yaxis]="sorStockOptions.yaxis!"></apx-chart>
                        </div>
                    </div>
                </div>

                <div class="col col-md-12">
                    <div class="card bg-transparent shadow-none mb-0">
                        <div class="card-body text-center">
                            <p class="mb-1 text-white">Bookings</p>
                            <h3 class="mb-3 text-white">{{Bookings.length > 0 ?
                                Bookings[Bookings.length - 1] : 0}}</h3>
                            <p class="font-13 text-white"><span
                                    class="{{calculateAverage(Bookings).positive ? 'text-success' : 'text-danger'}}"><i
                                        class="lni {{calculateAverage(Bookings).positive ? 'lni-arrow-up' : 'lni-arrow-down'}}"></i>
                                    {{calculateAverage(Bookings).percentage}}%</span> vs last 7 days</p>
                            <apx-chart #chartObj [chart]="bookingsOptions.chart!" [colors]="bookingsOptions.colors!"
                                [dataLabels]="bookingsOptions.dataLabels!" [fill]="bookingsOptions.fill!"
                                [grid]="bookingsOptions.grid!" [markers]="bookingsOptions.markers!"
                                [plotOptions]="bookingsOptions.plotOptions!" [series]="bookingsOptions.series!"
                                [stroke]="bookingsOptions.stroke!" [title]="bookingsOptions.title!"
                                [tooltip]="bookingsOptions.tooltip!" [yaxis]="bookingsOptions.yaxis!"></apx-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-xl-2">
            <div class="col d-flex">
                <div class="card radius-10 w-100">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <h6 class="mb-0">Purchase Overview</h6>
                            </div>
                            <div class="dropdown ms-auto">
                                <form [formGroup]="vehiclePurchaseChart">
                                    <ng-select class="radius-10" formControlName="reportSelect" [clearable]="false">
                                        <ng-option value="30days">Last 30 Days</ng-option>
                                        <ng-option value="currentQuarter">This Quarter</ng-option>
                                        <ng-option value="currentYear">This Year</ng-option>
                                        <ng-option value="lastQuarter">Last Quarter</ng-option>
                                        <ng-option value="6months">Last 6 Months</ng-option>
                                        <ng-option value="previousYear">Last Year</ng-option>
                                    </ng-select>
                                </form>

                            </div>
                        </div>
                        <apx-chart #chartObj [chart]="purchaseOverviewOptions.chart!" [colors]="purchaseOverviewOptions.colors!"
                            [dataLabels]="purchaseOverviewOptions.dataLabels!" [fill]="purchaseOverviewOptions.fill!"
                            [grid]="purchaseOverviewOptions.grid!" [markers]="purchaseOverviewOptions.markers!"
                            [plotOptions]="purchaseOverviewOptions.plotOptions!" [series]="purchaseOverviewOptions.series!"
                            [stroke]="purchaseOverviewOptions.stroke!" [title]="purchaseOverviewOptions.title!"
                            [tooltip]="purchaseOverviewOptions.tooltip!" [xaxis]="purchaseOverviewOptions.xaxis!"
                            [yaxis]="purchaseOverviewOptions.yaxis!"></apx-chart>
                    </div>
                </div>
            </div>

            <div class="col d-flex">
                <div class="card radius-10 w-100">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <h6 class="mb-0">Sale Overview</h6>
                            </div>
                            <div class="dropdown ms-auto">
                                <form [formGroup]="vehicleSaleChart">
                                    <ng-select class="radius-10" formControlName="reportSelect" [clearable]="false">
                                        <ng-option value="30days">Last 30 Days</ng-option>
                                        <ng-option value="currentQuarter">This Quarter</ng-option>
                                        <ng-option value="currentYear">This Year</ng-option>
                                        <ng-option value="lastQuarter">Last Quarter</ng-option>
                                        <ng-option value="6months">Last 6 Months</ng-option>
                                        <ng-option value="previousYear">Last Year</ng-option>
                                    </ng-select>
                                </form>

                            </div>
                        </div>
                        <apx-chart #chartObj [chart]="saleOverviewOptions.chart!" [colors]="saleOverviewOptions.colors!"
                            [dataLabels]="saleOverviewOptions.dataLabels!" [fill]="saleOverviewOptions.fill!"
                            [grid]="saleOverviewOptions.grid!" [markers]="saleOverviewOptions.markers!"
                            [plotOptions]="saleOverviewOptions.plotOptions!" [series]="saleOverviewOptions.series!"
                            [stroke]="saleOverviewOptions.stroke!" [title]="saleOverviewOptions.title!"
                            [tooltip]="saleOverviewOptions.tooltip!" [xaxis]="saleOverviewOptions.xaxis!"
                            [yaxis]="saleOverviewOptions.yaxis!"></apx-chart>
                    </div>
                </div>
            </div>

            <button (click)="showAccountCodeModal()">Show Account Code Modal</button>
        </div>
    </div>
}
