import {Component, ViewChild}                             from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatPaginator, PageEvent}                          from '@angular/material/paginator';
import {MatTableDataSource}                               from '@angular/material/table';
import {Lead}                                             from '../leads/models/lead.model';
import {ChartOptions}                                     from '../../shared/chart-options';
import {ChartService}                                     from '../../shared/services/chart.service';
import {ApiService}                                       from '../../shared/services/api.service';
import {NgbModal}                                         from "@ng-bootstrap/ng-bootstrap";
import {
    AccountCodeModalComponent
}                                                         from "../accounts/components/modals/account-code-modal/account-code-modal.component";
import {AuthService}                                      from "../../core/services/auth.service";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss'
})
export class DashboardComponent
{
    public vehiclePurchasedOptions: Partial<ChartOptions>;
    public enquiriesOptions: Partial<ChartOptions>;
    public vehiclesSoldOptions: Partial<ChartOptions>;
    public sorStockOptions: Partial<ChartOptions>;
    public bookingsOptions: Partial<ChartOptions>;
    public saleOverviewOptions: Partial<ChartOptions>;
    public purchaseOverviewOptions: Partial<ChartOptions>;

    //Leads Data & Variables
    public displayedColumns: string[] = ['leadId', 'vehicleId', 'name', 'statusId'];
    public dataSource: MatTableDataSource<Lead> = new MatTableDataSource();
    // selection = new SelectionModel<Lead>(true, []);
    @ViewChild(MatPaginator) public paginator: MatPaginator;

    public pageSize: number = 12;
    public length: number = 0;
    public pageIndex: number = 0;
    public pageEvent: PageEvent;
    public currentPage: number = 0;

    //Dashboard Results
    public VehiclesInStock: [] = [];
    public Enquiries: [] = [];
    public VehiclesSold: [] = [];
    public SORStock: [] = [];
    public Bookings: [] = [];

    public single: any[];
    public multi: any[];
    public view: any[] = [700, 400];

    // options
    public showXAxis = true;
    public showYAxis = true;
    public gradient = false;
    public showLegend = true;
    public showXAxisLabel = true;
    public xAxisLabel = 'Days';
    public showYAxisLabel = true;
    public yAxisLabel = 'Cars Sold';
    public showSalesOverview: boolean = false;
    public type: string = '30days';

    public vehiclePurchaseChart: UntypedFormGroup = new UntypedFormGroup({
        reportSelect: new UntypedFormControl(this.type, [Validators.required]),
    });

    public vehicleSaleChart: UntypedFormGroup = new UntypedFormGroup({
        reportSelect: new UntypedFormControl(this.type, [Validators.required]),
    });

    public colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    };

    constructor(
        public apiService: ApiService,
        public authService: AuthService,
        private chartService: ChartService,
        private modalService: NgbModal,
    )
    {
        this.single = [];
        this.vehiclePurchasedOptions = this.chartService.vehiclePurchasedOptions();
        this.purchaseOverviewOptions = this.chartService.salesOverview();
        this.saleOverviewOptions = this.chartService.salesOverview();
        this.enquiriesOptions = this.chartService.DealOptions();
        this.vehiclesSoldOptions = this.chartService.VehiclesSoldOptions();
        this.sorStockOptions = this.chartService.sorStockOptions();
        this.bookingsOptions = this.chartService.bookingOptions();

        this.vehicleSaleChart.valueChanges.subscribe(() => {
            this.getSaleOverview();
        });

        this.vehiclePurchaseChart.valueChanges.subscribe(() => {
            this.getPurchaseOverview();
        });

        this.apiService.get('dashboard/primary-graphs').subscribe((data: any) => {
            this.VehiclesInStock = data.vehicles_in_stock;
            this.vehiclePurchasedOptions.series = [{
                data: data.vehicles_in_stock
            }];

            this.Enquiries = data.enquiries;
            this.enquiriesOptions.series = [{
                data: data.enquiries
            }];

            this.VehiclesSold = data.vehicles_sold;
            this.vehiclesSoldOptions.series = [{
                data: data.vehicles_sold
            }];

            this.SORStock = data.sale_or_return_stock;
            this.sorStockOptions.series = [{
                data: data.sale_or_return_stock
            }];

            this.Bookings = data.bookings;
            this.bookingsOptions.series = [{
                data: data.bookings
            }];
        });

        this.getSaleOverview();
        this.getPurchaseOverview();
    }

    public calculateAverage(array: [])
    {
        const cloneArray = [...array];
        let lastVal = cloneArray.pop() ?? 0;
        const sum = cloneArray.reduce((a, b) => a + b, 0);
        const avg = (sum / cloneArray.length) || 0;

        let value = Number(((avg / lastVal) * 100).toFixed(1).replace(/[.,]0+$/, ''));
        const positive = avg >= lastVal ? false : true;
        // debugger;
        value = avg == lastVal ? 0 : value;
        if (lastVal > 0) {
            return {
                percentage: value,
                positive: positive
            };
        } else {
            return {
                percentage: Number((avg * 100).toFixed(1).replace(/[.,]0+$/, '')),
                positive: false
            };
        }
    }

    public getSaleOverview(): void
    {
        this.apiService.get(`dashboard/vehicle-overview`, {
            params: {
                period: this.vehicleSaleChart.get('reportSelect')?.value,
                type: 'sale'
            }
        }).subscribe((data: any) => {
            this.saleOverviewOptions.series = [{
                name: 'Sale',
                data: data
            }];
        });
    }

    public getPurchaseOverview(): void
    {
        this.apiService.get(`dashboard/vehicle-overview`, {
            params: {
                period: this.vehiclePurchaseChart.get('reportSelect')?.value,
                type: 'purchase'
            }
        }).subscribe((data: any) => {
            this.purchaseOverviewOptions.series = [{
                name: 'Purchases',
                data: data
            }];
        });
    }

    public showAccountCodeModal(): void
    {
        const dialogRef = this.modalService.open(AccountCodeModalComponent, {
            size: 'xl',
            scrollable: false,
            centered: true
        });

        dialogRef.result.then((response: any) => {
            console.log('Back pass from modal', response);
        }).catch(error => null);
    }
}
