import {Component} from '@angular/core';
import {AuthService} from "../core/services/auth.service";
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
    constructor(
        public authService: AuthService,
        public activatedRoute: ActivatedRoute,
    ) {
    }
}
